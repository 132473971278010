/* Basic rich text css */
.ProseMirror > * + * {
  margin-top: 0.75em;
}
.ProseMirror ul {
  list-style-type: disc;
  list-style-position: outside;
  padding: 0 1rem;
}
.ProseMirror ol {
  list-style-type: decimal;
  list-style-position: outside;
  padding: 0 1rem;
}

.ProseMirror:focus {
  outline: none;
}

.ProseMirror code {
  background-color: rgba(97, 97, 97, 0.1);
  color: #616161;
}
.ProseMirror pre {
  background: #0d0d0d;
  border-radius: 0.5rem;
  color: #fff;
  font-family: 'JetBrainsMono', monospace;
  padding: 0.75rem 1rem;
}
.ProseMirror pre code {
  background: none;
  color: inherit;
  font-size: 0.8rem;
  padding: 0;
}
.ProseMirror mark {
  background-color: #faf594;
}
.ProseMirror img {
  height: auto;
  max-width: 100%;
}
.ProseMirror hr {
  margin: 1rem 0;
}
.ProseMirror blockquote {
  border-left: 2px solid rgba(13, 13, 13, 0.1);
  padding-left: 1rem;
}
.ProseMirror hr {
  border: none;
  border-top: 2px solid rgba(13, 13, 13, 0.1);
  margin: 2rem 0;
}
.ProseMirror ul[data-type='taskList'] {
  list-style: none;
  padding: 0;
}
.ProseMirror ul[data-type='taskList'] li {
  align-items: center;
  display: flex;
}
.ProseMirror ul[data-type='taskList'] li > label {
  flex: 0 0 auto;
  margin-right: 0.5rem;
  user-select: none;
}
.ProseMirror ul[data-type='taskList'] li > div {
  flex: 1 1 auto;
}

.ProseMirror {
}

.ProseMirror h1 {
  font-size: 2em;
}
.ProseMirror h2 {
  font-size: 1.5em;
}
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  line-height: 1.1;
}

/* editor css */
.editor {
  background-color: #fff;
  border: 1px solid rgb(209 213 219);
  /* border-radius: 0.5rem; */
  color: #0d0d0d;
  display: flex;
  flex-direction: column;
  max-height: 660px;
}
.editor__header {
  align-items: center;
  border-bottom: 1px solid rgb(209 213 219);
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  padding: 0.25rem;
}
.editor__content {
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1.25rem 1rem;
  -webkit-overflow-scrolling: touch;
}

/* menu bar css */
.divider {
  background-color: rgba(0, 0, 0, 0.1);
  height: 1.25rem;
  margin-left: 0.5rem;
  margin-right: 0.75rem;
  width: 2px;
}

/* menu item css */
.menu-item {
  background-color: transparent;
  border: none;
  /* border-radius: 0.5rem; */
  color: #4d656f;
  height: 1.75rem;
  margin-right: 0.25rem;
  padding: 0.25rem;
  width: 1.75rem;
}
.menu-item svg {
  fill: currentColor;
  height: 100%;
  width: 100%;
}
.menu-item:hover,
.menu-item.is-active {
  background-color: #434275;
  color: #fff;
}

/* table css */
.ProseMirror table {
  font-family: 'Inter';
  border-collapse: collapse;
  border-radius: 0.5rem;
  margin: 0;
  overflow: hidden;
  table-layout: fixed;
  width: 100%;
}
.ProseMirror table tr {
  vertical-align: middle;
  padding: 12px;
  height: 40px;
}
.ProseMirror table td,
.ProseMirror table th {
  border: 1px solid #ced4da;
  box-sizing: border-box;
  min-width: 1em;
  padding: 3px 5px;
  position: relative;
  vertical-align: top;
}
.ProseMirror table td > *,
.ProseMirror table th > * {
  margin-bottom: 0;
}
.ProseMirror table th {
  padding: 8px;
  vertical-align: middle;
  text-transform: uppercase;
  font-weight: lighter;
  text-align: left;
  color: #4d656f;
}
.ProseMirror table .selectedCell:after {
  background: rgba(200, 200, 255, 0.4);
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  position: absolute;
  z-index: 2;
}
.ProseMirror table .column-resize-handle {
  background-color: #adf;
  bottom: -2px;
  position: absolute;
  right: -2px;
  pointer-events: none;
  top: 0;
  width: 4px;
}
.ProseMirror table p {
  margin: 0;
}
.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}
.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
